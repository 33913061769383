import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const CommercialManagement = () => {
    return (
        <Layout>
            <Seo title="Commercial Management" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Commercial Management</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="card mb-3 border-0">
                        <div className="row gx-3">
                            <div className="col-lg-6">
                                <div className="card-body gray-bg d-flex flex-column">
                                    <p className="card-text">
                                        Delta Corp Bulk Logistics has an experienced team of dry-bulk shipping professionals that have
                                        a proven track record of beating the dry cargo index and are able to optimize your vessel
                                        earnings. The Owners therefore benefit from Revenue Maximization and Risk Diversification.
                                    </p>
                                    <p>
                                        We provide full management services including Commercial and Technical to Owners that include:
                                    </p>

                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item gray-bg ">
                                            Concluding period, trip and voyage fixtures on your behalf
                                        </li>
                                        <li className="list-group-item gray-bg">Performance monitoring</li>
                                        <li className="list-group-item gray-bg">Invoicing, Laytime and demurrage calculations</li>
                                        <li className="list-group-item gray-bg">
                                            Hedging activities for an Owner – FFA & Marine fuel hedging
                                        </li>
                                        <li className="list-group-item gray-bg">Claims handling</li>
                                        <li className="list-group-item gray-bg">
                                            Operational matters including full post-fixture support
                                        </li>
                                        <li className="list-group-item gray-bg">Full voyage results reporting and analysis</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <StaticImage
                                    transformOptions={{ cropFocus: "attention" }}
                                    alt="Sales team"
                                    src="../../../images/other/bulker5.jpg"
                                    aspectRatio={16 / 9}
                                    width={696}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default CommercialManagement
